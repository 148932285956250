
import Vue from 'vue'

interface LanguageBoxComputed {
    languages: Object;
    translations: Object;
    isMobile: boolean;
    englishIconUri: string;
    swedishIconUri: string;
}

export default Vue.extend<{}, {}, LanguageBoxComputed, {}>({
    name: 'language-box',
    computed: {
        languages() {
            return this.$store.state.languages;
        },
        translations() {
            return this.$store.state.translations;
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
        englishIconUri() {
            return this.$store.state.englishIconUri;
        },
        swedishIconUri() {
            return this.$store.state.swedishIconUri;
        }
    },
})
