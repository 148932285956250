import {  IMenuItem, IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'toggleExpanded' | 'setOpen'| 'getChildren'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;

export interface IGetChildrenPayload {
	id: string
	level: number
}
export interface IAddMenuItemsPayload {
	menuItem: IMenuItem,
	items: IMenuItem[]
}

// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

export const toggleExpanded: ActionCreator<IMenuItem> = menuItem => ({
	type: 'toggleExpanded',
	payload: menuItem
})

export const setOpen: ActionCreator<any> = data => ({
	type: 'setOpen',
	payload: data
})
export const getChildren: ActionCreator<IMenuItem> = data => ({
	type: 'getChildren',
	payload: data
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}
const toggleExpandedAction: ActionHandler<IMenuItem> = ({ commit, dispatch }, { payload }) => {
	console.log("In toggle action")
	commit({
		type: "toggleExpanded",
		payload: payload
	})
	if(payload.children.length < 1){
		console.log("In toggle action 2")
		dispatch(getChildren(payload))
	}
}
const getChildrenAction: ActionHandler<IMenuItem> = ({ commit, state }, {payload}) => {
	console.log("In get childen action")
	api.getMenuItems(state.endpoints.getChildren, {id: payload.id, level: payload.level})
		.then(res => {
			commit({
				type: "addMenuItems",
				payload: {menuItem: payload, items: res},
			})
		})
		.catch(error => {
			console.error(error)
		})
}

const setOpenAction: ActionHandler<IState> = ({ commit }) => {
	commit({
		type: 'setOpen',
	})
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	toggleExpanded: toggleExpandedAction,
	getChildren: getChildrenAction,
	setOpen: setOpenAction,
}



export default actions;
