
import Vue from 'vue'
import Menu from './components/Menu.vue'
import Search from './components/Search.vue'
import Language from './components/Language.vue'

// Interfaces for component typechecking!
interface AppData {
	
}

interface AppMethods {

}

interface AppComputed {
	message: string,
	menuOpen: boolean,
	searchOpen: boolean,
	languageOpen: boolean,
	translations: [],
	otherLanguage: string,
	logotype: Object,
	startpageLink: string,
	isMobile: boolean,
}

interface AppProps {
	
}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	mounted() {
        if(window.innerWidth <= 1024){
			this.$store.commit({
				type: 'setIsMobile',
				payload: true,
			}) 	
		}
		window.addEventListener('resize', () => {
			this.$store.commit({
				type: 'setIsMobile',
				payload: window.innerWidth <= 1024,
			}) 	
		})
    },
	computed: {
		message(){
			return this.$store.getters.message;
		},
		menuOpen(){
			return this.$store.state.menuOpen;
		},
		searchOpen() {
			return this.$store.state.searchOpen;
		},
		languageOpen() {
			return this.$store.state.languageOpen;
		},
		translations() {
            return this.$store.state.translations;
        },
		otherLanguage() {
			return this.$store.state.otherLanguage;
		},
		logotype() {
			return this.$store.state.logotype;
		},
		startpageLink() {
			return this.$store.state.startpageLink;
		},
		isMobile() {
			return this.$store.state.isMobile;
		}
	},	
	components: {
		Menu,
		Search,
		Language
	},
})
