import { MutationTree, Mutation } from 'vuex';
import { IMenuItem, IState } from './state';
import { ActionPayload, IAddMenuItemsPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const setMenuOpenMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.menuOpen = payload;
}

const setSearchOpenMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.searchOpen = payload;
}

const addMenuItemsMutation: MutationHandler<IAddMenuItemsPayload> = (state, { payload }) => {
	if(payload.menuItem){
		Object.assign(payload.menuItem, {children: payload.items})
	}
}
const toggleExpandedMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded})
}

const setIsMobileMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.isMobile = payload;
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	addMenuItems: addMenuItemsMutation,
	toggleExpanded: toggleExpandedMutation,
	setMenuOpen: setMenuOpenMutation,
	setSearchOpen: setSearchOpenMutation,
	setIsMobile: setIsMobileMutation,
}



export default mutations
