
import Vue from 'vue'
import { IMenuItem } from './../store/state'
import MenuItem from './MenuItem.vue'
import Language from './Language.vue'
import { FocusTrap } from 'focus-trap-vue'

//import MenuItem from ;
interface MenuData {
    popupItem?: Element,
}

interface MenuMethods {
    toggleMenu(): void,
    hideMenu(e:any): void,
}
interface MenuComputed {
    menuOpen: boolean
    menuItems: []
    translations: []
    languageOpen: boolean,
    languages: Object,
    isMobile: boolean,
}

interface MenuProps {
    menuItems: IMenuItem[] 
}

const component: Function = Vue.extend<MenuData, MenuMethods, MenuComputed, MenuProps>({
    name: 'Menu',
    data() {
        return {
            popupItem: undefined,
        }
    },
    computed: {
        menuItems() {
            return this.$store.getters.menuItems;
        },
        menuOpen(){
			return this.$store.state.menuOpen;
		},
        translations() {
            return this.$store.state.translations;
        },
        languageOpen() {
            return this.$store.state.languageOpen;
        },
        languages() {
            return this.$store.state.languages;
        },
        isMobile() {
            return this.$store.state.isMobile;
        }
	},	
    methods: {
        toggleMenu(){
			document.body.classList.toggle("hide-scroll")
			this.$store.commit({
				type: 'setMenuOpen',
				payload: !this.menuOpen
			});
			if(this.$store.state.isMobile){
                this.$store.commit({
                    type: 'setLanguageOpen',
                    payload: true
                })
            }
		},
        hideMenu(e){          
            const menuWrapper = this.$refs.menuWrapper as Element
            const closeButton = this.$refs.closeButton as Element

            if(!menuWrapper.contains(e.target) || e.target.isEqualNode(closeButton)){
                document.body.classList.toggle("hide-scroll")
                this.$store.commit({
                    type: 'setMenuOpen',
                    payload: false,
                })   
                if(this.$store.state.isMobile){
                    this.$store.commit({
                        type: 'setLanguageOpen',
                        payload: false
                    })
                }        
            }
            
        }
	},
    components: {
		MenuItem,
        Language,
        FocusTrap
	}
})

export default component
