import { Getter } from "vuex";
import { IState } from './state';

const message: Getter<IState, IState> = (state) => `!!${state.message}!!`;
const activeSubmenu: Getter<IState, IState> = state => state.activeSubmenu;
const menuItems: Getter<IState, IState> = state => state.menuItems;

export default {
	message,
	activeSubmenu,
	menuItems,
}
