
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { toggleExpanded } from '../store/actions'


interface MenuItemMethods {
    toggleClick(): void
}

interface MenuItemComputed {
	
}

interface MenuItemProps {
    item: IMenuItem
	
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>({
    name: 'MenuItem',
    props: {
		item: {},
	},
    data() {
        return {
            isOpen: false,
        }
    },
    methods: {
        toggleClick() {
            console.log("In toggleclick")
            this.item.isOpen = !this.item.isOpen
            this.$store.dispatch(toggleExpanded(this.$props.item))
        }
    }, 
    computed: {
        levelClass() {
            return 'level-' + this.$props.item.level;
        },
        translations() {
            return this.$store.state.translations;
        }
    },
	components: {
		
	}
})
