
import Vue from 'vue';
import { FocusTrap } from 'focus-trap-vue'

interface SearchBoxData {
	query: string,
}

interface SearchBoxMethods {
	toggleSearch(e:any): void,
	clearQuery(): void,
}

interface SearchBoxComputed {
	translations: Object,
	searchOpen: boolean,
	searchPage: string,
}

export default Vue.extend<SearchBoxData, SearchBoxMethods, SearchBoxComputed, {}>({
	name: 'search-box',
	data() {
		return {
			query: ''
		}
	},
	methods: {
		toggleSearch(e) {
			const searchBox = this.$refs.searchBox as Element
            const closeSearch = this.$refs.closeSearch as Element
			if(!searchBox.contains(e.target) || e.target.isEqualNode(closeSearch)){
				document.body.classList.toggle("hide-scroll")
				this.$store.commit({
					type: 'setSearchOpen',
					payload: !this.searchOpen
				})
			}
		},
		clearQuery() {
			this.query = '';
		},
	},
	computed: {
		translations() {
			return this.$store.state.translations;
		},
		searchOpen() {
			return this.$store.state.searchOpen;
		},
		searchPage() {
			return this.$store.state.searchPage;
		}
	},
	components: {
		FocusTrap
	}

})
