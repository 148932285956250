export interface IState {
	message: string,
	activeSubmenu?: string,
	endpoints: {
		getChildren: string
	},
	menuItems: IMenuItem[],
	menuOpen: boolean,
	searchOpen: boolean,
	isMobile: boolean,
	translations: Object,
	searchPage: string,
	languages: Object,
	logotype: Object,
	startpageLink: string,
	englishIconUri: string,
	swedishIconUri: string
}

export interface IMenuItem {
	name: string,
	uri: string,
	isCurrent: boolean,
	isOpen: boolean,
	id: string,
	isExpanded: boolean,
	hasChildren: boolean,
	children: IMenuItem[],
	level: number,
}

const deafultState: IState = {
	menuOpen: false,
	searchOpen: false,
	isMobile: false,
	message: '',
	activeSubmenu: undefined,
	endpoints: {
		getChildren: ''
	},
	menuItems: [],
	translations: {},
	searchPage: '',
	languages: {},
	logotype: {},
	startpageLink: '',
	englishIconUri: '',
	swedishIconUri: ''
}

export default deafultState;
